$ratio: 3.5/2.5;
$width: 280px;
$height: calc(#{$width} * #{$ratio});

.plant-card {
    border: 1px solid #b2e097;
    border-radius: 10px;
    padding: 10px;
    background-color: #b2e097;
    width: $width;
    height: $height;

    min-width: $width;
    min-height: $height;

    margin-left: 0.5em;
    margin-right: 0.5em;
    margin-bottom: 0.5em;

    display: flex;
    flex-direction: column;

    p {
        margin: 0px;
    }

    .title {
        z-index: 2;
        border: 1px solid #333333;
        background-color: #ffffff;
        border-radius: 8px;
        padding: 2px 5px;

        font-weight: 700;
        margin-bottom: -5px;
    }

    .inner {
        background-color: #333333;
        height: 100%;
        border: 5px solid #b2e097;
        // padding: 5px;

        canvas {
            touch-action: auto !important;
        }
    }

    .debug {
        position: absolute;
        z-index: 10;
        left: 0px;
        right: 0px;
        bottom: -30px;
        background-color: rgba($color: #ff0000, $alpha: 0.9);

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        color: #000000;
    }
}
