.plants {
    .flex {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-self: center;
        flex-wrap: wrap;

        transition: all 1s linear;
    }

    .flex-space {
        width: 20px;
        background-color: #ff0000;
    }
}

.search {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background-color: #dddddd;
    // padding: 10px;

    border-bottom: 1px solid #333333;

    .search-inner {
        width: 100%;
        max-width: 700px;
        display: flex;
        flex-direction: row;
        // justify-content: center;
        align-items: center;

        // background-color: #ff0000;
        padding: 10px;
    }

    .search-box {
        flex-grow: 1;
        align-self: flex-end;

        padding: 0px 10px;
        display: flex;
        flex-direction: row;

        .flex-grow {
            flex-grow: 1;
        }
        input {
            flex-grow: 1;
            // max-width: 300px;
            // width: 100%;
            height: 28px;
            font-size: 18px;
            padding: 2px 10px;
        }
    }
    .icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 20px;
    }
    .divider {
        margin-left: 10px;
        margin-right: 10px;
    }
}

.filters {
    display: flex;
    flex-direction: row;
    // justify-content: center;
    align-items: center;

    background-color: #dddddd;
    padding: 0px 10px;

    border-bottom: 0px solid #333333;

    height: 0px;
    overflow: hidden;

    display: flex;

    transition: all 0.25s linear;

    h3 {
        margin: 0px;
    }
    &.show {
        padding: 10px;
        height: auto;
        // height: 100%;
        overflow: visible;
        border-bottom: 1px solid #333333;
    }
}
