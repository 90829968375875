.home {
    .flex-vertical {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: center;
        // pointer-events: none;
    }
    .flex-horizontal {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        // pointer-events: none;
        & > * {
            // pointer-events: all;
        }
    }
    .icon {
        font-size: 100px;
        color: #111111;
        margin-left: 10px;
        margin-right: 10px;
        // color: red;
        &:hover {
            color: rgb(75, 248, 196);
        }
    }
    h1 {
        margin: 0px;
    }
    .home-image {
        width: 250px !important;
        height: 250px !important;
        background-color: #eeeeee;
        // border: 1px solid #111111;
        // max-width: 250px;
    //    maxWidth: '250px', "maxHeight": '250px', "height": '250px' }}
    }
}

.section {
    border: 1px solid #b2e097;

    .title {
        background-color: #daffc5;
        border-bottom: 1px solid #b2e097;
        font-size: 20px;
        padding: 5px 10px;
        font-weight: 700;
    }
    .inner {
        padding: 10px;
    }
}
